import React from 'react'
import { useAppDispatch, useAppSelector } from '../../App/hooks'
import { getProtocolThunk } from '../../features/protocol/protocolSlice'
import { selectUserHospital } from '../../features/user/userSlice';

let initialized = false;
export const protocolWithRdrQuery = (WrappedComponent) => (props) => {
  const dispatch = useAppDispatch();
  const userHospital = useAppSelector(selectUserHospital)
  const rdrQuery = `?hospital=${userHospital || ''.replace(/"/g, '')}`
  if (!initialized) {
    initialized = true;
    dispatch(getProtocolThunk());
  }

  return <WrappedComponent {...props} rdrQuery={rdrQuery}/>
}
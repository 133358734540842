import { FieldStateDefinition, FieldStateMap } from '../../App/apiWrapper';
import { MapEntries } from '../../App/types';
import { FieldKey } from './index';
import { extractFieldKeys } from './internal/extractFieldKeys';
import { fieldIndices } from './internal/fieldIndices';
import { indexedUiElementId } from './internal/indexedUiElementId';

export const applyDefaultState = (defaultState: MapEntries<FieldStateDefinition>, data: any): FieldStateMap => {
  const newDefaultState: FieldStateMap = {};
  const entries = defaultState.entries || [];
  entries.forEach((fieldDefinition) => {
    const { elementId, forEach, ...rest } = fieldDefinition;
    if (forEach == null) {
      newDefaultState[elementId] = {
        ...rest,
      };
    } else {
      const fieldKeys: FieldKey[] = extractFieldKeys(data, forEach);
      fieldKeys.forEach(key => {
        const indices = fieldIndices(key);
        const elementId = indexedUiElementId(fieldDefinition, indices);
        newDefaultState[elementId] = {
          ...rest,
        };
      });
    }
  });
  return newDefaultState;
};

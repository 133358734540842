import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSummary, HasFieldViolations } from '../../App/apiWrapper'
import { RootState } from "../../App/store";
import { resetViolations, setViolations } from "../violations/violationsSlice";

interface Summary {
  dateTime: string,
  isDay: boolean,
  total: number,
  reqNurses: number,
  beds: {
    [bed: number]: number
  }
}


export interface ProtocolSummary {
  dataAccurate: boolean;
  hospital: string;
  limit: number;
  numberOfBeds: number;
  offset: number;
  summaries: Summary[];
  totalCount: number;
  searchCriteria: Omit<GetSummaryParams, 'limit' | 'offset' | 'keepExisting'>
  keepExisting?: boolean;
}

let initialState: ProtocolSummary = {
  dataAccurate: false,
  hospital: '',
  limit: 25,
  numberOfBeds: 0,
  offset: 0,
  summaries: [],
  totalCount: 0,
  searchCriteria: {
    hospital: null,
    dateFrom: null,
    dateTo: null
  }
}

export interface GetSummaryParams {
  hospital: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  offset: number;
  limit: number;
  keepExisting?: boolean;
}

export const getSummaryThunk = createAsyncThunk<{ protocolSummary: ProtocolSummary } & HasFieldViolations, GetSummaryParams>(
  'post/protocolSummary',
  async ({ keepExisting, ...input }, { dispatch }) => {
    const data = await getSummary(input);
    if (data.violations.length) {
      dispatch(setViolations({ violations: data.violations }))
    } else {
      dispatch(resetViolations())
      data.protocolSummary.keepExisting = keepExisting;
    }
    return data;
  }
)

export const protocolSummarySlice = createSlice({
  name: 'protocolSummary',
  initialState,
  reducers: {
    resetProtocolSummary: (state) => {
      state = initialState

      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryThunk.fulfilled, (state, { payload: { violations, protocolSummary } }) => {
        if (!violations.length) {
          if (!protocolSummary.keepExisting) {
            state = protocolSummary
          } else {
            state = {
              ...protocolSummary,
              summaries: [
                ...state.summaries,
                ...protocolSummary.summaries
              ]
            }
          }

          return state;
        }
      })
  }
})

export const selectProtocolSummary = (state: RootState) => state.protocolSummary

export const {
  resetProtocolSummary
} = protocolSummarySlice.actions

export default protocolSummarySlice.reducer

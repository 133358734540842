import React from 'react'
import { useAppSelector } from '../../App/hooks'
import { withDisplayRule } from '../../features/displayRules/withDisplayRule'
import { selectRefDataList } from '../../features/refdata/selectRefDataList'
import { FormSelectComponent } from './FormSelect'

const FormRdrSelectComponent = (props) => {
  const options = useAppSelector((state) => selectRefDataList(state, props.id));
  return (
    <FormSelectComponent {...props} options={options} />
  )
}

export const FormRdrSelect = withDisplayRule(FormRdrSelectComponent)
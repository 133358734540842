import React from 'react'
import { useAppSelector } from '../../App/hooks'
import { selectRefDataList } from '../../features/refdata/selectRefDataList'
import { FormToggleButtonGroupComponent } from './FormToggleButtonGroup'

export const FormRdrToggleButtonGroup = (props) => {
  const options = useAppSelector((state) => selectRefDataList(state, props.id));
  return (
    <FormToggleButtonGroupComponent {...props} options={options} />
  )
}
import Typography from '@mui/material/Typography';
import React from 'react';

export const getHelpTextItems = (violationMessages?: string[]): React.ReactElement[] => {
  const helperTextItems: React.ReactElement[] = [];
  if (violationMessages) {
    violationMessages.forEach((value, index) =>
      helperTextItems.push(<Typography fontSize='0.75rem' key={index}>{value}</Typography>));
  }
  return helperTextItems;
};

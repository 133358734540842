import styled from "@emotion/styled";
import React, { useCallback } from 'react';
import { Navigate, useOutlet } from 'react-router-dom';
import { I18nTypes } from "../../App/types";
import { withUser } from '../../features/user/withUser';
import { useAuth } from '../../hooks/useAuth';
import { useIsUserAdmin } from "../../hooks/useIsUserAdmin";
import { AppBar, Page } from './AppBar';

interface ProtectedLayoutProps {
  anonymousUserRoute: string;
}

const PrintStyles = styled.div`
  @media print {
    @page {
      size: auto;
      margin: 5px;
    }

    button,
    .app_bar_root,
    .summary_generic_q {
      display: none;
    }

    .summary_generic_table {
      margin: 0;
      padding: 0;
    }
  }
`;

const ProtectedLayoutComponent: React.FC<ProtectedLayoutProps> = ({ anonymousUserRoute }) => {
  const { userAuthenticated } = useAuth();
  const outlet = useOutlet();
  const { user, admin } = useIsUserAdmin();

  const getRoutes = useCallback(
    () => {
      let final: Page[] = []
      if (admin) {
        final.push({ id: 'MDNS.PG.SIGNUP' as I18nTypes, path: 'register' })
        final.push({ id: 'MDNS.PG.SUMMARY' as I18nTypes, path: 'summary' })
        final.push({ id: 'MDNS.PG.CHANGEPASSWORD' as I18nTypes, path: 'changepassword' })
      }
      if (user) {
        final.push({ id: 'MDNS.PG.PROTOCOL' as I18nTypes, path: 'protocol' })
      }

      return final;
    },
    [admin, user]
  )

  if (!userAuthenticated) {
    return <Navigate to={anonymousUserRoute}/>;
  }

  return (
    <PrintStyles>
      <AppBar
        pages={getRoutes()}
      />
      {outlet}
    </PrintStyles>
  );
};

export const ProtectedLayout = withUser(ProtectedLayoutComponent)

import { FC } from 'react'
import { useAppSelector } from '../../App/hooks'
import { ProtocolState, selectProtocol } from '../../features/protocol/protocolSlice'
import { Question } from './Question'

export const Questions: FC = () => {
  const { questionsDisplay } = useAppSelector<ProtocolState>(selectProtocol)
  return (
    <>
      {questionsDisplay.map(({ id, children, ...rest }) => (
        <Question id={id} key={id} childQuestions={children} {...rest}/>
      ))}
    </>

  )
}
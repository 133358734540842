import React, { FC } from "react";
import { useAppSelector } from "../../App/hooks";
import { selectProtocolSummary } from "../../features/summary/protocolSummarySlice";
import { I18nFragment } from '../common/I18nFragment';
import { getNumberOfBedsArr } from "./getNumberOfBedsArr";

export const SummaryTableSubHeader: FC = () => {
  const { numberOfBeds } = useAppSelector(selectProtocolSummary);
  const numberOfBedsArray = getNumberOfBedsArr(numberOfBeds);

  return (
    <tr className="bold">
      <td><I18nFragment id="MDNS.PRTCLSMMR.DATETIME"/></td>
      {numberOfBedsArray.map((id) => (
        <td id={`protocol_bed_${id}`} key={`protocol_bed_${id}`}>{id}</td>
      ))}

      <td className="italic"><I18nFragment id="MDNS.PRTCLSMMR.DAY"/></td>
      <td className="italic"><I18nFragment id="MDNS.PRTCLSMMR.NIGHT"/></td>

      <td className="italic"><I18nFragment id="MDNS.PRTCLSMMR.DAY"/></td>
      <td className="italic"><I18nFragment id="MDNS.PRTCLSMMR.NIGHT"/></td>
    </tr>
  )
}
import { Paper } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { theme } from "../../App/theme";
import { getSummaryThunk, selectProtocolSummary } from "../../features/summary/protocolSummarySlice";
import { selectUserHospital } from '../../features/user/userSlice';
import { FormButton } from "../common/FormButton";
import { FormDatePicker } from "../common/FormDatePicker";
import { FormRdrSelect } from "../common/FormRdrSelect";

const SummarySearchComponent: FC = () => {
  const dispatch = useAppDispatch();
  const hospital = useAppSelector(selectUserHospital)
  const [selectedHospital, setSelectedHospital] = useState(hospital);
  const [dateTo, setDateTo] = useState<string | null>(null)
  const [dateFrom, setDateFrom] = useState<string | null>(null)
  const { limit } = useAppSelector(selectProtocolSummary)

  const queryData = () => {
    dispatch(getSummaryThunk({
      hospital: selectedHospital,
      dateFrom,
      dateTo,
      offset: 0,
      limit
    }))
  }

  const handleHospitalChange = useCallback(
    (event) => {
      setSelectedHospital(event.target.value)
    },
    []
  )

  const handleDateFrom = useCallback(
    (value) => {
      setDateFrom(value)
    },
    []
  )

  const handleDateTo = useCallback(
    (value) => {
      setDateTo(value)
    },
    []
  )

  return (
    <Paper
      sx={{
        p: 3,
        my: 5,
        mx: 'auto',
        maxWidth: 1200,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      }}
      className="summary_generic_q"
      elevation={2}
    >
      <FormRdrSelect
        id="MDNS.PRTCLSMMR.HOSPITAL"
        required
        onChange={handleHospitalChange}
        value={selectedHospital}
        sx={{
          minWidth: '20%',
          [theme.breakpoints.down('md')]: {
            width: '100%'
          },
        }}
      />
      <FormDatePicker
        value={dateFrom}
        handleValueChange={handleDateFrom}
        id="MDNS.PRTCLSMMR.DATEFROM"
        views={['day']}
        sx={{
          [theme.breakpoints.down('md')]: {
            mt: 3,
            width: '100%'
          },
        }}
      />
      <FormDatePicker
        value={dateTo}
        handleValueChange={handleDateTo}
        id="MDNS.PRTCLSMMR.DATETO"
        views={['day']}
        sx={{
          [theme.breakpoints.down('md')]: {
            mt: 3,
            width: '100%'
          },
        }}
      />
      <FormButton
        id="MDNS.BTN.VIEWAGGRDATA"
        onClick={queryData}
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          [theme.breakpoints.down('md')]: {
            width: '100%'
          },
        }}
      />
    </Paper>
  )
}

export const SummarySearch = SummarySearchComponent
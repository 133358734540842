import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { callChangePassword, ChangePasswordRequest, GenericOutput } from '../../../App/apiWrapper'
import { RootState } from '../../../App/store'
import { I18nTypes, ViolationLevel } from '../../../App/types'

export interface ChangePasswordState extends ChangePasswordRequest {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  violationArgs?: string[];
}

const initialState: ChangePasswordState = {
  oldPassword: '',
  newPassword: '',
  matchingNewPassword: '',
  violationLevel: null,
  violationId: null,
};

export const changePasswordThunk = createAsyncThunk<GenericOutput, ChangePasswordRequest>(
  'changePassword/user',
  async (input) => await callChangePassword(input)
);

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    setOldPassword: (state, action: PayloadAction<string>) => {
      state.oldPassword = action.payload;
    },
    setNewPassword: (state, action: PayloadAction<string>) => {
      state.newPassword = action.payload;
    },
    setMatchingNewPassword: (state, action: PayloadAction<string>) => {
      state.matchingNewPassword = action.payload;
    },
    resetChangePassword: (state, action: PayloadAction<void>) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        const response = action.payload
        let violations = response.violations;
        if (!violations || violations.length === 0) {
          state.violationLevel = ViolationLevel.INFO;
          state.violationId = 'MDNS.CHGPWD.IR.001';
          state.oldPassword = '';
          state.newPassword = '';
          state.matchingNewPassword = '';
        }
      })
      .addCase(changePasswordThunk.rejected, (state, action) => {
        state.violationLevel = ViolationLevel.ERROR;
        state.violationId = 'MDNS.COMMON.ER.001';
      })
  }
});

export const selectChangePassword = (state: RootState) => state.changePassword;

export const {
  setOldPassword,
  setNewPassword,
  setMatchingNewPassword,
  resetChangePassword
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;

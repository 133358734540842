import React from 'react';
import { Navigate } from 'react-router';
import { useIsUserAdmin } from '../hooks/useIsUserAdmin';

interface UserRoleNavigateProps {
  rootPath: string;
  userPath: string;
  adminPath: string;
}

export const UserRoleNavigate: React.FC<UserRoleNavigateProps> = ({ rootPath, userPath, adminPath }) => {
  const { user, admin } = useIsUserAdmin();

  if (user) {
    const to = `${rootPath}/${userPath}`;
    return (<Navigate to={to}/>);
  }
  if (admin) {
    const to = `${rootPath}/${adminPath}`;
    return (<Navigate to={to}/>);
  }
  return null;
};

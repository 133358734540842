import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { App } from './App/App';
import { store } from './App/store';
import { initCsrfInfo } from './helpers/csrf/initCsrfInfo';

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container!);

(async () => {
  await initCsrfInfo();

  root.render(
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App/>
      </LocalizationProvider>
    </Provider>
  );
})();

import { ConfigState } from "../features/config/configSlice";
import { ProtocolState, SubmitProtocolState } from '../features/protocol/protocolSlice'
import { GetSummaryParams, ProtocolSummary } from "../features/summary/protocolSummarySlice";
import { doGet, doPost, doPut } from './httpClient'
import {
  ActionRef,
  BooleanType,
  CodeTableRef,
  CodeTableRefType,
  FieldViolation,
  I18nTypes,
  MapEntries,
  NumberType,
  RefIdType,
  ScreenRef,
  StringType
} from './types'

export interface HasFieldViolations {
  violations: FieldViolation[];
}

export interface LoginRequest {
  userName: string;
  password: string;
}

export const callLogin = async (request: LoginRequest, authToken: string): Promise<UserResponse> =>
  doPost('/api/user/login', request, false, () => authToken);

export const callLogout = async (): Promise<void> => doPost('/api/logout');

export interface User {
  userName: string;
  hospital: RefIdType;
  roles?: string[];
}

export interface RegisterRequest extends User {
  password: string;
  matchingPassword: string;
}

export interface UserResponse extends HasFieldViolations {
  user: User | null;
}

export const callRegisterUser = async (
  request: RegisterRequest
): Promise<UserResponse> => doPost('/api/user/register', request);

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  matchingNewPassword: string;
}

export interface GenericOutput extends HasFieldViolations {
}

export const callChangePassword = async (
  request: ChangePasswordRequest
): Promise<GenericOutput> => doPut('/api/user/changePassword', request);

export const submitProtocol = async (
  request: SubmitProtocolState
): Promise<SubmitProtocolState & HasFieldViolations> => doPost('/api/protocol', request);

export interface TranslationsMapEntries {
  key: I18nTypes;
  value: string;
}

export interface TranslationsMap {
  entries: TranslationsMapEntries[];
}

export const getTranslationMap = async (): Promise<TranslationsMap> => doGet('/api/translationmap');

export interface UserProfile {
  user: User | null;
  locale: string;
  dateFormat: string;
  hospitalName: string | null;
}

export const getUserProfile = async (): Promise<UserProfile> => doGet('/api/userprofile');
export const getAppConfig = async (): Promise<ConfigState> => doGet('/api/config/environment');

export interface ReferenceData {
  id: string;
  name: string;
}

export interface ReferenceDataMapEntry {
  key: CodeTableRef;
  value: ReferenceData[];
}

export interface ReferenceDataMap {
  screen: string;
  entries: ReferenceDataMapEntry[];
}

export const getReferenceDataMap = async (screenRef: ScreenRef, rdrQuery: string): Promise<ReferenceDataMap> =>
  doGet(`/api/referencedata/${screenRef}${rdrQuery}`);

// Display Rules
export enum DisplayRuleType {
  OPTIONAL = 'OPTIONAL',
  MANDATORY = 'MANDATORY',
  HIDDEN = 'HIDDEN',
  READ_ONLY = 'READ_ONLY',
}

export enum FieldType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  NUMBER = 'NUMBER',
  REFID = 'REFID',
}

export enum RefAttrType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface ValueWrapper {
  stringValue: StringType;
  longValue: NumberType;
  integerValue: NumberType;
  decimalValue: NumberType;
}

export interface ForEachDefinition {
  attr: string;
  idxVar: string;
  uiIdxVar: string
  forEach: ForEachDefinition;
}

export interface FieldDefinition {
  elementId: string;
  forEach: ForEachDefinition | null;
}

export interface FieldStateDefinition extends FieldState, FieldDefinition {
}

export interface FieldState {
  displayRuleType: DisplayRuleType | null;
  codeTables: CodeTableRefType;
}

export interface FieldStateMap {
  [key: string]: FieldState;
}

export interface FieldMetadataDefinition extends FieldMetadataState, FieldDefinition {
}

export interface FieldMetadataState {
  type: FieldType;
  maxLength: NumberType;
  maxIntegerPart: NumberType;
  maxFractionPart: NumberType;
  mayBeNegative: BooleanType;
  refAttrType: RefAttrType | null;
  codeTables: CodeTableRefType;
}

export interface FieldMetadataMap {
  [key: string]: FieldMetadataState;
}

export interface ConditionDefinition {
  expression: string;
  variables: MapEntries<VariableMapEntry>;
}

export interface VariableMapEntry {
  key: string;
  value: ValueWrapper;
}

export interface NodeDefinition {
  forEach: ForEachDefinition | null;
  condition: ConditionDefinition;
  branches: NodeDefinition[];
  consequences: FieldStateDefinition[];
}

export interface DisplayRuleDefinition {
  ruleId: string;
  branches: NodeDefinition[];
}

export interface DisplayRuleKey {
  screen: ScreenRef;
  action: ActionRef;
}

export interface DisplayRulesOutput extends DisplayRuleKey {
  defaultState: MapEntries<FieldStateDefinition> | null;
  metadata: MapEntries<FieldMetadataDefinition> | null;
  displayRuleDefs: DisplayRuleDefinition[] | null;
}

export const getDisplayRulesOutput = async (key: DisplayRuleKey): Promise<DisplayRulesOutput> =>
  doGet(`/api/displayRules/${key.screen}/${key.action}`);

export const getProtocol = async (): Promise<{ protocol: ProtocolState } & HasFieldViolations> =>
  doGet(`/api/protocol`);

export const getSummary = async (params: GetSummaryParams): Promise<{ protocolSummary: ProtocolSummary } & HasFieldViolations> =>
  doPost(`/api/protocolsummary`, params);

export const fetchCsrfInfo = async () => {
  const headers = {
    'cache-control': 'no-cache, no-store, must-revalidate',
    pragma: 'no-cache',
  };
  const response = await fetch('/api/config/csrfTokenInfo', {
    headers,
  });
  return response.json();
};
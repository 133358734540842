import { Paper, Typography } from '@mui/material'
import { SelectInputProps } from '@mui/material/Select/SelectInput'
import React, { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../App/hooks'
import { RefIdType } from '../../App/types'
import {
  ProtocolState,
  selectProtocol,
  setProtocolBed,
  setProtocolDate,
  setProtocolTime
} from '../../features/protocol/protocolSlice'
import { FormDatePicker } from '../common/FormDatePicker'
import { FormRdrSelect } from '../common/FormRdrSelect'
import { selectUserProfile } from "../../features/user/userSlice";

export const GeneralProtocolQuestions: FC = () => {
  const dispatch = useAppDispatch();
  const {
    bed,
    date,
    time,
  } = useAppSelector<ProtocolState>(selectProtocol)
  const { hospitalName } = useAppSelector(selectUserProfile)

  const handleDateChange = (value) => {
    dispatch(setProtocolDate(value))
  }

  const handleBedChange: SelectInputProps<RefIdType>['onChange'] = (event) => {
    dispatch(setProtocolBed(event.target.value))
  }

  const handleTimeChange: SelectInputProps<RefIdType>['onChange'] = (event) => {
    dispatch(setProtocolTime(event.target.value))
  }
  return (
    <Paper sx={{ p: 3, my: 5, mx: 'auto', maxWidth: 1200 }} elevation={2}>
      {hospitalName && <Typography variant="h5">{hospitalName}</Typography>}
      <FormDatePicker
        value={date}
        fullWidth
        handleValueChange={handleDateChange}
        id="MDNS.PRTCL.DATE"
        views={['day']}
      />

      <FormRdrSelect
        id="MDNS.PRTCL.BED"
        required
        fullWidth
        name="bed"
        onChange={handleBedChange}
        value={bed}
      />
      <FormRdrSelect
        id="MDNS.PRTCL.TIME"
        required
        fullWidth
        name="time"
        onChange={handleTimeChange}
        value={time}
      />
    </Paper>
  )
}
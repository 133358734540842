import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from '../../App/store';
import { ActionRef, ScreenRef } from '../../App/types';

export interface UiState {
  screen: ScreenRef | null;
  action: ActionRef | null;
  rdrQuery: string;

  showLoader?: boolean;
}

export const initialState: UiState = {
  screen: null,
  action: null,
  rdrQuery: '',
  showLoader: false
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUiState: (state, action: PayloadAction<UiState>) => {
      const {
        screen: newScreen,
        action: newAction,
        rdrQuery: newRdrQuery,
      } = action.payload;
      state.screen = newScreen;
      state.action = newAction;
      state.rdrQuery = newRdrQuery;
    },
    startLoader: (state) => {
      state.showLoader = true
      return state
    },
    stopLoader: (state) => {
      state.showLoader = false
      return state
    }
  },
});

export const selectUiState = (state: RootState) => state.ui;

export const selectRdrMapKey = (state: RootState) => {
  const { screen, rdrQuery } = state.ui;
  if (!screen) {
    return null;
  }
  return `${screen}${rdrQuery}`;
};

export const { setUiState, startLoader, stopLoader } = uiSlice.actions;

export default uiSlice.reducer;

export const findQuestion = (tree, value) => {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.id === value) {
      return node;
    }

    if (node.children && node.children.length > 0) {
      const foundNode = findQuestion(node.children, value);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
}
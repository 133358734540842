import React, { ComponentType } from "react";
import { useAppDispatch } from "../../App/hooks";
import { getAppConfigThunk } from "./configSlice";


export const withConfig = <T extends object>(WrappedComponent: ComponentType<T>) => (props) => {
  const dispatch = useAppDispatch();
  dispatch(getAppConfigThunk());
  return <WrappedComponent {...props}/>;
}

import React, { ComponentType } from 'react';
import { DisplayRuleType } from '../../App/apiWrapper';
import { useAppSelector } from '../../App/hooks';
import { FieldViolation, I18nTypes, ViolationLevel } from '../../App/types';
import { getDisplayRuleElementId } from '../../helpers/displayRules/getDisplayRuleElementId';
import { i18n } from '../i18n/i18n';
import { selectTranslationEntries, Translations } from '../i18n/i18nSlice';
import { selectViolations } from '../violations/violationsSlice';
import { selectDisplayRuleType } from './displayRulesSlice';

interface ViolationProps {
  hasViolation?: boolean;
  violationMessages?: string[];
  violationLevel?: ViolationLevel;
}

export interface WithDisplayRuleProps extends ViolationProps {
  id: I18nTypes;
  displayRuleIndexes?: number[];
  disabled?: boolean;
}

const getViolationProps = (violations?: FieldViolation[], entries?: Translations): ViolationProps => {
  return violations && violations.length > 0 ? {
    hasViolation: true,
    violationMessages: violations.map(value => i18n(value.ruleId, entries, ...value.args)),
    violationLevel: violations[0].violationLevel,
  } : {};
};

export const withDisplayRule = <T extends WithDisplayRuleProps>(Component: ComponentType<T>) => {
  return (props: T) => {
    const {
      id: displayRuleId,
      displayRuleIndexes,
    } = props;
    const id = getDisplayRuleElementId(displayRuleId, displayRuleIndexes);
    const violations = useAppSelector((state) => selectViolations(state, id));
    const translationEntries = useAppSelector(selectTranslationEntries);
    const violationProps = getViolationProps(violations, translationEntries);
    const displayRuleType = useAppSelector((state) => selectDisplayRuleType(state, id));
    if (displayRuleType === null || displayRuleType === DisplayRuleType.HIDDEN) {
      return null;
    }
    const disabled = displayRuleType === DisplayRuleType.READ_ONLY ? { disabled: true } : {};
    return <Component
      {...props}
      {...disabled}
      {...violationProps}
    />;
  };
};

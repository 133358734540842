import { ForEachDefinition } from '../../../App/apiWrapper';
import { FieldKey } from '../index';
import { addFieldKey } from './addFieldKey';

export const extractFieldKeys = (data: any, forEach: ForEachDefinition): FieldKey[] => {
  const fieldKeys = [];
  const fieldIndices = [];
  addFieldKey(data, forEach, fieldIndices, fieldKeys);
  return fieldKeys;
};

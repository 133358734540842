import { ForEachDefinition } from '../../../App/apiWrapper';
import { Attribute, AttributeDefinition } from '../index';
import { formatIndex } from './formatIndex';
import { getAttributeValue } from './getAttributeValue';
import { singularAttribute } from './singularAttribute';

export const getNextData = (data: any, forEach: ForEachDefinition, index: number): any => {
  const nextAttributeDefinition: AttributeDefinition = {
    pattern: `${forEach.attr}${formatIndex(index)}`,
  };
  const attribute: Attribute = singularAttribute(nextAttributeDefinition);
  return getAttributeValue(data, attribute);
};

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  DisplayRuleDefinition,
  DisplayRuleKey,
  DisplayRulesOutput,
  FieldMetadataDefinition,
  FieldMetadataMap,
  FieldStateDefinition,
  FieldStateMap,
  getDisplayRulesOutput
} from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { MapEntries } from '../../App/types';
import { applyDefaultMetadata } from '../../helpers/displayRules/applyDefaultMetadata';
import { applyDefaultState } from '../../helpers/displayRules/applyDefaultState';
import { applyDisplayRuleDefinitions } from '../../helpers/displayRules/applyDisplayRuleDefinitions';
import { getDisplayRuleKey } from '../../helpers/displayRules/getDisplayRuleKey';
import { selectUiState } from '../ui/uiSlice';


export interface DisplayRuleState {
  defaultState: MapEntries<FieldStateDefinition> | null;
  currentState?: FieldStateMap | null;
  metadata: MapEntries<FieldMetadataDefinition> | null;
  currentMetadata?: FieldMetadataMap | null;
  displayRuleDefs: DisplayRuleDefinition[] | null;
}

export type DisplayRuleStateMap = {
  [key in string]: DisplayRuleState;
}

export const initialState: DisplayRuleStateMap = {};

export const getDisplayRulesOutputThunk = createAsyncThunk<DisplayRulesOutput, DisplayRuleKey>(
  'displayRules/displayrulesoutput',
  async (key) => {
    return await getDisplayRulesOutput(key);
  }
);

export interface DisplayRuleInput<T> extends DisplayRuleKey {
  data: T;
}

const handleDisplayRuleOutput = (action: PayloadAction<DisplayRulesOutput>, state: DisplayRuleStateMap) => {
  const {
    screen,
    action: displayRuleAction,
    defaultState,
    metadata,
    displayRuleDefs,
  } = action.payload;
  const key = getDisplayRuleKey(screen, displayRuleAction);
  state[key] = {
    defaultState,
    metadata,
    displayRuleDefs,
  };
};

export const displayRulesSlice = createSlice({
  name: 'displayRules',
  initialState,
  reducers: {
    runMetadataRules: (state, action: PayloadAction<DisplayRuleInput<any>>) => {
      const {
        screen,
        action: displayRuleAction,
        data,
      } = action.payload;
      const key = getDisplayRuleKey(screen, displayRuleAction);
      const metadata = state[key].metadata;
      state[key].currentMetadata = applyDefaultMetadata(metadata!, { ...data });
    },
    runStateRules: (state, action: PayloadAction<DisplayRuleInput<any>>) => {
      const {
        screen,
        action: displayRuleAction,
        data,
      } = action.payload;
      const key = getDisplayRuleKey(screen, displayRuleAction);
      const {
        defaultState,
        displayRuleDefs,
      } = state[key];
      const newState = applyDefaultState(defaultState!, data);
      applyDisplayRuleDefinitions(newState, displayRuleDefs!, data);
      state[key].currentState = newState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDisplayRulesOutputThunk.fulfilled, (state, action) => {
        handleDisplayRuleOutput(action, state);
      });
  },
});

export const selectDisplayRuleStateMap = (state: RootState) => state.displayRules;

export const selectDisplayRulesInitialized = createSelector(
  [
    selectDisplayRuleStateMap,
    selectUiState,
  ],
  (selectDisplayRuleStateMap, { screen, action }) =>
    screen && action ? !!selectDisplayRuleStateMap[getDisplayRuleKey(screen, action)] : null
);

export const selectDisplayRulesState = createSelector(
  [
    selectDisplayRuleStateMap,
    selectUiState,
  ],
  (displayRules, { screen, action }) => {
    const key = getDisplayRuleKey(screen, action);
    const displayRule = displayRules[key];
    return displayRule ? displayRule.currentState || null : null;
  },
);

export const selectDisplayRulesMetadata = createSelector(
  [
    selectDisplayRuleStateMap,
    selectUiState,
  ],
  (displayRules, { screen, action }) => {
    const key = getDisplayRuleKey(screen, action);
    const displayRule = displayRules[key];
    return displayRule ? displayRule.currentMetadata || null : null;
  },
);

export const selectDisplayRuleType = createSelector(
  [
    selectDisplayRulesState,
    (state, id): string => id,
  ],
  (currentState, id: string) => (currentState ? (currentState[id] ? currentState[id]!.displayRuleType : null) : null),
);

export const { runMetadataRules, runStateRules } = displayRulesSlice.actions;

export default displayRulesSlice.reducer;

import { Grid, Paper, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { resetProtocolSummary, selectProtocolSummary } from "../../features/summary/protocolSummarySlice";
import { SummaryLoadMore } from "../summarySearch/SummaryLoadMore";
import { SummarySearch } from "../summarySearch/SummarySearch";
import { SummaryTable } from "../summaryTable/SummaryTable";

import { withPageInit } from "./withPageInit";
import { FormButtonComponent } from "../common/FormButton";

const SummaryPageComponent: FC = () => {
  const dispatch = useAppDispatch();
  const { totalCount } = useAppSelector(selectProtocolSummary)

  useEffect(
    () => {
      return () => {
        dispatch(resetProtocolSummary())
      }
    },
    /*  eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  )

  return (
    <Grid item xs={12}>
      <SummarySearch/>
      <Paper className="summary_generic_table" sx={{ p: 3, my: 5, mx: 'auto', maxWidth: 1200 }} elevation={2}>
        <Typography variant="body1">{totalCount}</Typography>
        <SummaryTable/>
        <SummaryLoadMore/>
        {!!totalCount &&
          <FormButtonComponent
            id="MDNS.BTN.PRINT"
            fullWidth
            onClick={window.print}
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
            }}
          />
        }
      </Paper>
    </Grid>
  );
}

export const SummaryPage = withPageInit(SummaryPageComponent)
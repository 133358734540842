import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'

import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../App/hooks'
import { I18nTypes } from '../../App/types'
import { resetLogin } from '../../features/user/login/loginSlice'
import { useAuth } from '../../hooks/useAuth'
import { FormButtonComponent } from '../common/FormButton'
import { FormTypography } from '../common/FormTypography'
import { UserAccountInfo } from './UserAccountInfo'

export interface Page {
  id: I18nTypes;
  path: string;
}

interface AppBarProps {
  pages: Page[];
}

export const AppBar: React.FC<AppBarProps> = ({ pages }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const { userAuthenticated, logout } = useAuth()
  const dispatch = useAppDispatch()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNavigate = (path: string) => {
    if (path) {
      navigate(path)
    }
  }

  const handleLogout = () => {
    dispatch(resetLogin())
    logout()
  }

  return (
    <MuiAppBar position="static" className="app_bar_root">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <FormTypography
            id="MDNS.HEADER"
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            boxSx={{ textTransform: 'uppercase' }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages?.map((page) => (
                <MenuItem
                  key={page.id}
                  onClick={handleClose}
                  component={Link} to={page.path}
                >
                  <FormTypography id={page.id} textAlign="center"/>
                </MenuItem>
              ))}
              {userAuthenticated && (
                <MenuItem key={'logout'} onClick={handleLogout}>
                  <FormTypography id="MDNS.PG.LOGOUT" textAlign="center"/>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <FormTypography
            id="MDNS.HEADER"
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages?.map((page) => (
              <FormButtonComponent
                id={page.id}
                key={page.id}
                onClick={() => handleNavigate(page.path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              />
            ))}
            {userAuthenticated && (
              <FormButtonComponent
                id="MDNS.PG.LOGOUT"
                key={'logout'}
                onClick={handleLogout}
                sx={{ my: 2, color: 'white', display: 'block' }}
              />
            )}
          </Box>
          {userAuthenticated && <UserAccountInfo/>}
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
}

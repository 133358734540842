import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../App/store'
import { I18nTypes } from '../../App/types'
import { selectDisplayRulesMetadata } from '../displayRules/displayRulesSlice'
import { selectRdrMapKey } from '../ui/uiSlice'
import { selectScreenRefDataMap } from './refdataSlice'

export const selectRefDataList = createSelector(
  [
    selectScreenRefDataMap,
    selectRdrMapKey,
    selectDisplayRulesMetadata,
    (state: RootState, id: I18nTypes) => id
  ],
  (screenRefDataMap, key, meta, id) => {
    if (!key || !meta || !meta[id] || !screenRefDataMap[key]) {
      return null
    }
    const fieldMeta = meta[id].codeTables
    return screenRefDataMap[key]![fieldMeta || id]
  }
)
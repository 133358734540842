import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAppConfig } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { ServerTimeZone } from "../../App/types";

export interface ConfigState {
  dateFormat: string | null;
  timeZone: ServerTimeZone | null;
  userRoleName: string | null;
  adminRoleName: string | null;
}

const initialState: ConfigState = {
  dateFormat: null,
  timeZone: null,
  userRoleName: null,
  adminRoleName: null,
};

export const getAppConfigThunk = createAsyncThunk<ConfigState>(
  'app/config',
  async () => await getAppConfig()
);

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppConfigThunk.fulfilled, (state, action) => {
        state = action.payload;

        return state;
      });
  },
});

export const selectAppConfig = (state: RootState) => state.config;

export default config.reducer;

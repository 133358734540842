import { CodeTableRef, StringType } from '../../App/types';
import { RefData } from './refdataSlice';

export const refDataName = (attributeRef: CodeTableRef | null, code: StringType, entries?: RefData): StringType => {
  if (!attributeRef || !entries) {
    return code;
  }
  const values = entries[attributeRef];
  const value = values.find((value) => value.id === code);
  return value ? value.name : code;
};

import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup/ToggleButtonGroup'
import React, { FC } from 'react'
import { ReferenceData } from '../../App/apiWrapper'
import { I18nTypes, RefIdType } from '../../App/types'
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule'

interface FormToggleProps extends WithDisplayRuleProps, ToggleButtonGroupProps {
  id: I18nTypes,
  options: ReferenceData[];
  value: RefIdType;
}

export const FormToggleButtonGroupComponent: FC<FormToggleProps> = ({ options, value, onChange, ...rest }) => (
  <ToggleButtonGroup
    color="primary"
    value={value}
    exclusive
    onChange={onChange}
    aria-label="Platform"
    {...rest}
  >
    {options.map(({ id, name }) => (
      <ToggleButton value={id} key={id}>{name}</ToggleButton>
    ))}
  </ToggleButtonGroup>
)

export const FormToggleButtonGroup = withDisplayRule(FormToggleButtonGroupComponent)
import { ButtonProps } from '@mui/material'
import Button from '@mui/material/Button'
import React, { FC } from 'react'
import { useAppSelector } from '../../App/hooks'
import { I18nTypes } from '../../App/types'
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule'
import { selectI18n } from '../../features/i18n/i18nSlice'

interface FormButtonProps extends ButtonProps, WithDisplayRuleProps {
  id: I18nTypes;
}

export const FormButtonComponent: FC<FormButtonProps> = ({ id, ...rest }) => (
  <Button
    {...rest}
  >
    {useAppSelector((state) => selectI18n(state, id))}
  </Button>
)

export const FormButton = withDisplayRule(FormButtonComponent)

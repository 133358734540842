import React, { FC } from "react";
import { SummaryTableHeader } from "./SummaryTableHeader";
import { SummaryTableSubHeader } from "./SummaryTableSubHeader";
import { SummaryTableContent } from "./SummaryTableContent";
import styled from "@emotion/styled";

const StyledTable = styled('table')`
  width: 100%;
  text-align: center;
  border: 1px solid black;
  border-collapse: collapse;
  
  th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  
  .bold {
    vertical-align: text-bottom;
    text-align: left;
    font-weight: bold;
  }
  .italic {
    font-style: italic;
  }
  .bed_weight {
    width: 5%;
  }
  .result {
    white-space: pre;
  }
`

export const SummaryTable: FC = () => (
  <StyledTable>
    <SummaryTableHeader/>
    <SummaryTableSubHeader/>
    <SummaryTableContent/>
  </StyledTable>
)

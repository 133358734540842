import React, { FC } from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter, Navigate } from 'react-router-dom'
import { HomeLayout } from '../components/layout/HomeLayout'
import { ProtectedLayout } from '../components/layout/ProtectedLayout'
import { ChangePasswordPage } from '../components/pages/ChangePasswordPage';
import { LoginPage } from '../components/pages/LoginPage'
import { ProtocolPage } from '../components/pages/ProtocolPage'
import { SignUpPage } from '../components/pages/SignUpPage'
import { SummaryPage } from "../components/pages/SummaryPage";
import { withConfig } from '../features/config/withConfig'
import { withTranslations } from '../features/i18n/withTranslations'
import { selectProtocol } from '../features/protocol/protocolSlice';
import { selectChangePassword } from '../features/user/changePassword/changePasswordSlice';
import { selectLogin } from '../features/user/login/loginSlice'
import { selectRegister } from '../features/user/register/registerSlice'
import { AuthProvider } from '../hooks/useAuth'
import { useAppSelector } from './hooks'
import { ActionRef, ScreenRef } from './types'
import { UserRoleNavigate } from './UserRoleNavigate';

const AUTH_USER_ROUTE = '/dashboard'
const USER_PATH = 'protocol'
const ADMIN_PATH = 'summary'
const ANONYM_USER_ROUTE = '/login'

const AppRoutesComponent: FC = () => {
  const loginState = useAppSelector(selectLogin)
  const registerState = useAppSelector(selectRegister)
  const changePassword = useAppSelector(selectChangePassword)
  const protocolState = useAppSelector(selectProtocol)
  const getLoginState = () => loginState
  const getRegisterState = () => registerState
  const getChangePassword = () => changePassword
  const getProtocolState = () => protocolState

  return (
    <BrowserRouter>
      <AuthProvider authUserRoute={AUTH_USER_ROUTE} anonymousUserRoute={ANONYM_USER_ROUTE}>
        <Routes>
          <Route element={<HomeLayout authUserRoute={AUTH_USER_ROUTE}/>}>
            <Route path="/" element={<Navigate to="/login"/>}/>
            <Route path="/login"
                   element={<LoginPage
                     screenRef={ScreenRef.LOGIN}
                     actionRef={ActionRef.LOGIN}
                     getData={getLoginState}
                   />}
            />
          </Route>
          <Route path="/dashboard" element={<ProtectedLayout anonymousUserRoute={ANONYM_USER_ROUTE}/>}>
            <Route path="" element={<UserRoleNavigate
              rootPath={AUTH_USER_ROUTE}
              userPath={USER_PATH}
              adminPath={ADMIN_PATH}
            />}/>
            <Route
              path="protocol"
              element={<ProtocolPage
                screenRef={ScreenRef.PROTOCOL}
                actionRef={ActionRef.CREATE}
                getData={getProtocolState}
              />}
            />
            <Route
              path="summary"
              element={<SummaryPage
                screenRef={ScreenRef.PROTOCOL_SUMMARY}
                actionRef={ActionRef.VIEW}
              />}
            />
            <Route path="register"
                   element={<SignUpPage
                     screenRef={ScreenRef.REGISTER}
                     actionRef={ActionRef.REGISTER}
                     getData={getRegisterState}
                   />}
            />
            <Route path="changepassword"
                   element={<ChangePasswordPage
                     screenRef={ScreenRef.CHANGE_PASSWORD}
                     actionRef={ActionRef.CHANGE_PASSWORD}
                     getData={getChangePassword}
                   />}
            />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

export const AppRoutes = withTranslations(withConfig(AppRoutesComponent))

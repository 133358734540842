import { SxProps, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nTypes } from '../../App/types';
import { selectI18n } from '../../features/i18n/i18nSlice';

type FormTypographyProps = TypographyProps & {
  component?: React.ElementType,
  id: I18nTypes,
  boxSx?: SxProps,
}

export const FormTypography: React.FC<FormTypographyProps> = (props) => {
  const {
    id,
    boxSx,
    ...rest
  } = props;

  return (
    <Typography
      {...rest}
    >
      <Box sx={boxSx}>
        {useAppSelector((state) => selectI18n(state, id))}
      </Box>
    </Typography>
  );
};

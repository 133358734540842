import { AccountCircle } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import React, { FC } from 'react'
import { useAppSelector } from '../../App/hooks'
import { selectUserProfile } from '../../features/user/userSlice'

export const UserAccountInfo: FC = () => {
  const userProfile = useAppSelector(selectUserProfile)

  if (!userProfile || !userProfile.user) {
    return null
  }

  const { userName } = userProfile.user

  return (
    <>
      <Box>
        <Typography variant="body2" align="justify">{userName}</Typography>
      </Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle/>
      </IconButton>
    </>
  )
}

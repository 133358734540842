import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog/Dialog'
import { TransitionProps } from '@mui/material/transitions'
import { FC, forwardRef, ReactElement, Ref } from 'react'
import { useAppSelector } from '../../App/hooks'
import { I18nTypes } from '../../App/types'
import { selectI18n } from '../../features/i18n/i18nSlice'
import { withTranslations } from '../../features/i18n/withTranslations'

const Transition = forwardRef(function Transition (
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})
interface ActionButtonProps {
  labelId: I18nTypes;
  onClick (): void;
}
interface ModalProps extends DialogProps {
  handleClose (): void;

  open: boolean;
  titleId: I18nTypes;
  contentId: I18nTypes;
  actions: ActionButtonProps[];
}

const ActionButton: FC<ActionButtonProps> = ({ labelId, onClick }) => (
  <Button onClick={onClick}>{useAppSelector((state) => selectI18n(state, labelId))}</Button>
)

const ModalComponent: FC<ModalProps> = ({ handleClose, open, titleId, contentId, actions }) => {
  const title = useAppSelector((state) => selectI18n(state, titleId))
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{sx: {backgroundColor: '#d7f9d7'}}}
    >
      {titleId && (<DialogTitle>{title}</DialogTitle>)}
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {useAppSelector((state) => selectI18n(state, contentId))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map(({ labelId, onClick }) => (
          <ActionButton labelId={labelId} onClick={onClick} key={labelId} />
        ))}

      </DialogActions>
    </Dialog>
  )
}

export const Modal = withTranslations<ModalProps>(ModalComponent)
import { FieldStateDefinition, ForEachDefinition } from '../../App/apiWrapper';
import { StringType } from '../../App/types';

export interface FieldIndex {
  idxVar: string;
  uiIdxVar: string;
  index: number;
}

export interface FieldKey {
  fieldIndices: FieldIndex[];
}

export interface AttributeDefinition {
  pattern: string;
  valueAttribute?: StringType;
  forEach?: ForEachDefinition;
}

export interface Attribute {
  pattern: string;
  valueAttribute?: StringType;
  fieldIndices: FieldIndex[];
}

export interface ConsequenceEntry {
  key: FieldKey | null;
  value: FieldStateDefinition[];
}

export const VARIABLE_PREFIX = "#";
export const NESTED_PROPERTY_DELIMITER = ".";
export const SIZE_SUFFIX = ".length";

import { NodeDefinition } from '../../../App/apiWrapper';
import { ConsequenceEntry, FieldKey } from '../index';
import { conditionMatched } from './conditionMatched';
import { extractFieldKeys } from './extractFieldKeys';
import { fieldIndices } from './fieldIndices';

export const evalBranches = (data: any, branches: NodeDefinition[], consequenceEntries: ConsequenceEntry[]) => {
  for (const branch of branches) {
    const { forEach, condition } = branch;
    if (forEach == null) {
      if (conditionMatched(data, condition, [])) {
        addConsequences(branch, null, consequenceEntries);
        evalBranches(data, branch.branches, consequenceEntries);
      }
    } else {
      const fieldKeys: FieldKey[] = extractFieldKeys(data, forEach);
      fieldKeys.forEach(key => {
        const indices = fieldIndices(key);
        if (conditionMatched(data, condition, indices)) {
          addConsequences(branch, key, consequenceEntries);
          evalBranches(data, branch.branches, consequenceEntries);
        }
      });
    }
  }
};

const addConsequences = (branch: NodeDefinition, fieldKey: FieldKey | null, consequenceEntries: ConsequenceEntry[]) => {
  consequenceEntries.push({ key: fieldKey, value: branch.consequences });
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { callRegisterUser, RegisterRequest, UserResponse } from '../../../App/apiWrapper'
import { RootState } from '../../../App/store'
import { I18nTypes, RefIdType, StringType, ViolationLevel } from '../../../App/types'

export interface RegisterState extends RegisterRequest {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  violationArgs?: string[];
  navigateTo: StringType;
}

const initialState: RegisterState = {
  userName: '',
  hospital: null,
  password: '',
  matchingPassword: '',
  violationLevel: null,
  violationId: null,
  navigateTo: null
}

export const registerUserThunk = createAsyncThunk<UserResponse, RegisterRequest>(
  'register/user',
  async (input) => await callRegisterUser(input)
)

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload
    },
    setHospital: (state, action: PayloadAction<RefIdType>) => {
      state.hospital = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload
    },
    setMatchingPassword: (state, action: PayloadAction<string>) => {
      state.matchingPassword = action.payload
    },
    resetRegistration: (state, action: PayloadAction<void>) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserThunk.fulfilled, (state, action) => {
        const userResponse = action.payload
        if (userResponse.user) {
          state.violationLevel = ViolationLevel.INFO
          state.violationId = 'MDNS.RGSR.IR.001'
          state.violationArgs = [state.userName]
          state.userName = ''
          state.password = ''
          state.matchingPassword = ''
        }
      })
      .addCase(registerUserThunk.rejected, (state, action) => {
        state.violationLevel = ViolationLevel.ERROR
        state.violationId = 'MDNS.COMMON.ER.001'
      })
  }
})

export const selectRegister = (state: RootState) => state.register

export const {
  setUserName,
  setHospital,
  setPassword,
  setMatchingPassword,
  resetRegistration
} = registerSlice.actions

export default registerSlice.reducer

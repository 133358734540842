import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getUserProfileThunk, selectUserInitialized } from './userSlice';

export interface WithUserProps {
}

export const withUser = <T extends WithUserProps>(Component: ComponentType<T>) => {
  return (props: T) => {
    const dispatch = useAppDispatch();
    const userInitialized = useAppSelector(selectUserInitialized);

    useEffect(() => {
      if (!userInitialized) {
        dispatch(getUserProfileThunk());
      }
    }, [dispatch, userInitialized]);

    if (!userInitialized) {
      return null;
    }

    return <Component {...props}/>;
  };
};

import LockPersonIcon from '@mui/icons-material/LockPerson'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import * as React from 'react'
import { ChangeEventHandler, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../App/hooks'
import { selectLogin, setPassword, setUserName } from '../../features/user/login/loginSlice'
import { useAuth } from '../../hooks/useAuth'
import { FormButton } from '../common/FormButton'
import { FormTextField } from '../common/FormTextField'
import { FormTypography } from '../common/FormTypography'
import { ViolationBanner } from '../common/ViolationBanner'
import { withPageInit } from './withPageInit'
import { FormPasswordField } from "../common/FormPasswordField";

const LoginPageComponent: React.FC = () => {
  const { login } = useAuth()
  const dispatch = useAppDispatch()
  const loginState = useAppSelector(selectLogin)
  const {
    userName,
    password,
    violationLevel,
    violationId
  } = loginState

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    login({
      userName,
      password,
    })
  }, [login, userName, password])

  const handleUserNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setUserName(event.target.value))
  }

  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setPassword(event.target.value))
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {violationLevel && (<ViolationBanner level={violationLevel} id={violationId}/>)}
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockPersonIcon/>
        </Avatar>
        <FormTypography
          id="MDNS.LGN.HEADER"
          component="h1"
          variant="h5"
        />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormTextField
            id="MDNS.LGN.USERNAME"
            margin="normal"
            required
            fullWidth
            name="userName"
            autoFocus
            onChange={handleUserNameChange}
            value={userName}
          />
          <FormPasswordField
            id="MDNS.LGN.PASSWORD"
            margin="normal"
            required
            fullWidth
            name="password"
            onChange={handlePasswordChange}
            value={password}
          />
          <FormButton
            id="MDNS.BTN.LOGIN"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          />
        </Box>
      </Box>
    </Container>
  )
}

export const LoginPage = withPageInit(LoginPageComponent)


import { useState } from 'react';
import { useAppSelector } from "../App/hooks";
import { selectAppConfig } from "../features/config/configSlice";
import { selectUserRoles } from "../features/user/userSlice";

export const useIsUserAdmin = () => {
  const [admin, setAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<boolean>(false);
  const { adminRoleName, userRoleName } = useAppSelector(selectAppConfig);
  const roles = useAppSelector(selectUserRoles)

  if (!admin && roles.indexOf(adminRoleName!) !== -1) {
    setAdmin(true);
  }

  if (!user && roles.indexOf(userRoleName!) !== -1) {
    setUser(true);
  }

  return { admin, user };
};
import { Attribute } from '../index';
import { nested } from './nested';
import { indexReplacement } from './indexReplacement';
import { getRootAttribute } from './getRootAttribute';

export const getAttributePath = (attribute: Attribute) => {
  const {
    fieldIndices,
    pattern,
    valueAttribute,
  } = attribute;
  const rootAttribute = getRootAttribute(pattern, fieldIndices, (value) => indexReplacement(value.idxVar));
  return valueAttribute ? nested([rootAttribute, valueAttribute]) : rootAttribute;
};

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { SelectInputProps } from '@mui/material/Select/SelectInput'
import React, { FC } from 'react'
import { useAppSelector } from '../../App/hooks'
import { I18nTypes, RefIdType } from '../../App/types'
import { WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule'
import { selectI18n } from '../../features/i18n/i18nSlice'
import { getHelpTextItems } from '../../features/violations/getHelpTextItems'

type FormSelectProps =
  SelectProps<RefIdType> &
  Partial<SelectInputProps<RefIdType>> &
  WithDisplayRuleProps & {
  id: I18nTypes;
  options: Array<{ id: string; name: string; }>
};

export const FormSelectComponent: FC<FormSelectProps> = (
  {
    id,
    hasViolation,
    violationMessages,
    fullWidth,
    onChange,
    value,
    options,
    sx = {},
  }
) => {

  const helperTextItems = getHelpTextItems(violationMessages)

  return (
    <FormControl
      fullWidth={fullWidth}
      {...(hasViolation ? { error: true } : {})}
      sx={{ mt: '16px', mb: '8px', ...sx }}
    >
      <InputLabel id={`select_label_${id}`}>
        {useAppSelector((state) => selectI18n(state, id))}
      </InputLabel>

      <Select
        labelId={`select_label_${id}`}
        id={`select_${id}`}
        value={value || ''}
        label={useAppSelector((state) => selectI18n(state, id))}
        onChange={onChange}
      >
        {options.map(({ id, name }) => (
          <MenuItem
            id={`select_menu_item_${id}`}
            key={`select_menu_item_${id}`}
            value={id}
          >
            {name}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{helperTextItems}</FormHelperText>

    </FormControl>
  )
}
import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { ActionRef, ScreenRef } from '../../App/types';
import {
  getDisplayRulesOutputThunk,
  runMetadataRules,
  runStateRules,
  selectDisplayRulesInitialized
} from '../../features/displayRules/displayRulesSlice';
import { getReferenceDataMapThunk, selectRefDataInitialized } from '../../features/refdata/refdataSlice';
import { selectUiState, setUiState } from '../../features/ui/uiSlice';

export interface WithPageProps {
  screenRef: ScreenRef;
  actionRef: ActionRef;
  rdrQuery?: string;

  getData?(): any;
}

export const withPageInit = <T extends WithPageProps>(Component: ComponentType<T>) => {
  return (props: T) => {
    const {
      screenRef,
      actionRef,
      getData,
      rdrQuery = ''
    } = props;
    const dispatch = useAppDispatch();
    const uiState = useAppSelector(selectUiState);
    const {
      screen,
      action,
      rdrQuery: uiRdrQuery,
    } = uiState;
    const refDataInitialized = useAppSelector(selectRefDataInitialized);
    const displayRulesInitialized = useAppSelector(selectDisplayRulesInitialized);
    const data = getData ? getData() : null;

    useEffect(() => {
      const newUiState = {
        screen: screenRef,
        action: actionRef,
        rdrQuery,
      };
      dispatch(setUiState(newUiState));
    }, [dispatch, uiState, screenRef, actionRef, rdrQuery]);

    useEffect(() => {
      if (!refDataInitialized && screen && screen === screenRef && uiRdrQuery === rdrQuery) {
        dispatch(getReferenceDataMapThunk({ screenRef, rdrQuery }));
      }
    }, [dispatch, refDataInitialized, screen, screenRef, rdrQuery, uiRdrQuery]);

    useEffect(() => {
      if (!displayRulesInitialized && screen && screen === screenRef && action && action === actionRef) {
        dispatch(getDisplayRulesOutputThunk({ screen: screen, action: action }));
      }
    }, [dispatch, displayRulesInitialized, screen, action, screenRef, actionRef]);

    useEffect(() => {
      if (displayRulesInitialized && screen && screen === screenRef && action && action === actionRef) {
        dispatch(runMetadataRules({
          screen,
          action,
          data,
        }));
        dispatch(runStateRules({
          screen,
          action,
          data,
        }));
      }
    }, [dispatch, displayRulesInitialized, data, screen, action, screenRef, actionRef]);

    if (!uiState.screen || !uiState.action || !refDataInitialized || !displayRulesInitialized) {
      return null;
    }

    return <Component {...props}/>;
  };
};

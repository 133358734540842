import { Dayjs } from 'dayjs';

export type NumberType = number | null;

export type StringType = string | null;

export type BooleanType = boolean | null;

export type RefIdType = string | null;

export type DateType = string | null;

export const defaultDateType: DateType = null;

export type DatePickerType = Dayjs | null;

export interface MapEntries<T> {
  entries: T[] | null;
}

export enum ScreenRef {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  PROTOCOL = 'PROTOCOL',
  PROTOCOL_SUMMARY = 'PROTOCOL_SUMMARY',
}

export enum ActionRef {
  LOGIN = 'login',
  REGISTER = 'register',
  CHANGE_PASSWORD = 'changePassword',
  CREATE = 'create',

  VIEW = 'VIEW',
}

export enum ViolationLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface Violation {
  ruleId: I18nTypes;
  violationLevel: ViolationLevel;
  args: string[];
}

export interface FieldViolation extends Violation {
  fieldId: string;
}

export type I18nTypes =
  | 'MDNS.HEADER'
  | 'MDNS.PG.HOME'
  | 'MDNS.PG.LOGIN'
  | 'MDNS.PG.PROFILE'
  | 'MDNS.PG.SETTINGS'
  | 'MDNS.PG.PROTOCOL'
  | 'MDNS.PG.LOGOUT'
  | 'MDNS.PG.SIGNUP'
  | 'MDNS.PG.SUMMARY'
  | 'MDNS.PRTCLSMMR.DATETIME'
  | 'MDNS.PRTCLSMMR.BEDNO'
  | 'MDNS.PRTCLSMMR.NURSESNOCUR'
  | 'MDNS.PRTCLSMMR.NURSESNOREQ'
  | 'MDNS.PRTCLSMMR.DAY'
  | 'MDNS.PRTCLSMMR.NIGHT'
  | 'MDNS.LGN.HEADER'
  | 'MDNS.BTN.LOGIN'
  | 'MDNS.LGN.USERNAME'
  | 'MDNS.LGN.PASSWORD'
  | 'MDNS.RGSR.USERNAME'
  | 'MDNS.RGSR.HOSPITAL'
  | 'MDNS.RGSR.DATEOFBIRTH'
  | 'MDNS.RGSR.PASSWORD'
  | 'MDNS.RGSR.CONFIRMPASSWORD'
  | 'MDNS.RGSR.IR.001'
  | 'MDNS.BTN.REGISTER'
  | 'MDNS.AUTH.ER.001'
  | 'MDNS.COMMON.ER.001'

  | 'MDNS.PG.CHANGEPASSWORD'
  | 'MDNS.CHGPWD.OLDPASSWORD'
  | 'MDNS.CHGPWD.NEWPASSWORD'
  | 'MDNS.CHGPWD.CONFIRMNEWPASSWORD'
  | 'MDNS.BTN.CHANGEPASSWORD'
  | 'MDNS.CHGPWD.IR.001'

  | 'MDNS.PRTCL.DATE'
  | 'MDNS.PRTCL.TIME'
  | 'MDNS.PRTCL.BED'
  | 'MDNS.BTN.SAVEANDSUBMIT'
  | 'MDNS.COMMON.THANKYOU'
  | 'MDNS.PRTCL.SUBMITTED'
  | 'MDNS.BTN.OK'

  | 'MDNS.PRTCLSMMR.DATEFROM'
  | 'MDNS.PRTCLSMMR.DATETO'
  | 'MDNS.BTN.VIEWAGGRDATA'
  | 'MDNS.BTN.LOADMORE'
  | 'MDNS.BTN.PRINT'
  ;

export type CodeTableRef = 'X.CT.HOSPITAL';

export type CodeTableRefType = CodeTableRef | null;

export enum ServerTimeZone {
  UTC = 'UTC'
}
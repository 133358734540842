import React from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nTypes } from '../../App/types';
import { selectI18n } from '../../features/i18n/i18nSlice';

interface FragmentProps {
  id: I18nTypes,
}

export const I18nFragment: React.FC<FragmentProps> = ({ id }) => (
  <>
    {useAppSelector((state) => selectI18n(state, id))}
  </>
);

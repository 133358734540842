import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from '../../../App/store';
import { I18nTypes, Violation, ViolationLevel } from '../../../App/types';
import { AuthState } from '../../../hooks/useAuth';

export interface LoginState extends AuthState {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
}

const initialState: LoginState = {
  userName: '',
  password: '',
  violationLevel: null,
  violationId: null,
};

const clearViolation = (state: LoginState) => {
  state.violationLevel = null;
  state.violationId = null;
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setViolation: (state, action: PayloadAction<Violation | null>) => {
      const violation = action.payload;
      if (violation) {
        state.violationLevel = violation.violationLevel;
        state.violationId = violation.ruleId;
      } else {
        clearViolation(state);
      }
    },
    resetViolation: (state, action: PayloadAction<void>) => {
      clearViolation(state);
    },
    resetLogin: (state, action: PayloadAction<void>) => {
      Object.assign(state, initialState);
    },
  },
});

export const selectLogin = (state: RootState) => state.login;

export const {
  setUserName,
  setPassword,
  setViolation,
  resetViolation,
  resetLogin,
} = loginSlice.actions;

export default loginSlice.reducer;

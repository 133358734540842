import React, { FC } from "react";
import { useAppSelector } from "../../App/hooks";
import { selectProtocolSummary } from "../../features/summary/protocolSummarySlice";
import { I18nFragment } from '../common/I18nFragment';

export const SummaryTableHeader: FC = () => {
  const { numberOfBeds } = useAppSelector(selectProtocolSummary);
  return (
    <tr>
      <th style={{ width: 120 }}></th>
      <th colSpan={numberOfBeds}><I18nFragment id="MDNS.PRTCLSMMR.BEDNO"/></th>
      <th style={{ width: 100 }} colSpan={2}><I18nFragment id="MDNS.PRTCLSMMR.NURSESNOCUR"/></th>
      <th style={{ width: 100 }} colSpan={2}><I18nFragment id="MDNS.PRTCLSMMR.NURSESNOREQ"/></th>
    </tr>
  )
}
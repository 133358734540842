import { TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField/TextField';
import React from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nTypes } from '../../App/types';
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { selectI18n } from '../../features/i18n/i18nSlice';
import { getHelpTextItems } from '../../features/violations/getHelpTextItems';

export type FormTextFieldProps = TextFieldProps & WithDisplayRuleProps & {
  id: I18nTypes,
};

export const FormTextFieldComponent: React.FC<FormTextFieldProps> = (props) => {
  const {
    id,
    hasViolation,
    violationMessages,
    violationLevel,
    ...rest
  } = props;

  const helperTextItems = getHelpTextItems(violationMessages);

  return (
    <TextField
      {...rest}
      label={useAppSelector((state) => selectI18n(state, id))}
      {...(hasViolation ? { error: true } : {})}
      helperText={(<>{helperTextItems}</>)}
    />
  );
};

export const FormTextField = withDisplayRule(FormTextFieldComponent);
import { configureStore } from '@reduxjs/toolkit'
import config from '../features/config/configSlice';
import displayRulesReducer from '../features/displayRules/displayRulesSlice';
import i18nReducer from '../features/i18n/i18nSlice';
import protocol from '../features/protocol/protocolSlice';
import refdataReducer from '../features/refdata/refdataSlice';
import protocolSummary from '../features/summary/protocolSummarySlice';
import uiReducer from '../features/ui/uiSlice';
import changePassword from '../features/user/changePassword/changePasswordSlice';
import loginReducer from '../features/user/login/loginSlice';
import registerReducer from '../features/user/register/registerSlice';
import userReducer from '../features/user/userSlice';
import violationsReducer from '../features/violations/violationsSlice';

export const store = configureStore({
  reducer: {
    i18n: i18nReducer,
    user: userReducer,
    refdata: refdataReducer,
    displayRules: displayRulesReducer,
    violations: violationsReducer,
    ui: uiReducer,
    login: loginReducer,
    register: registerReducer,
    changePassword,
    protocol,
    protocolSummary,
    config,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

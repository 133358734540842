import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { FC } from 'react'
import { AppRoutes } from './AppRoutes'
import { theme } from './theme'
import { AppSpinner } from "../components/common/AppSpinner";

export const App: FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline/>
    <AppRoutes/>
    <AppSpinner/>
  </ThemeProvider>
)

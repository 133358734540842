import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getTranslationMap, TranslationsMap } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { I18nTypes } from '../../App/types';
import { i18n } from './i18n';
import { parseTranslations } from './parseTranslations';

export type Translations = {
  [key in I18nTypes]: string;
}

export interface I18nState {
  entries?: Translations;
  initialized: boolean;
}

const initialState: I18nState = {
  initialized: false,
};

export const getTranslationMapThunk = createAsyncThunk<TranslationsMap>(
  'i18n/translationmap',
  async (_) => {
    return await getTranslationMap();
  }
);

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTranslationMapThunk.fulfilled, (state, action) => {
        state.entries = parseTranslations(action.payload.entries);
        state.initialized = true;
      });
  },
});

export const selectTranslationsInitialized = (state: RootState) => state.i18n.initialized;

export const selectTranslationEntries = (state: RootState) => state.i18n.entries;

export const selectI18n = createSelector(
  [
    selectTranslationEntries,
    (state: RootState, key: I18nTypes | null) => key,
    (state: RootState, ...replacements: string[]) => replacements
  ],
  (entries, key, replacements) => key ? i18n(key, entries, ...replacements) : key,
);

export default i18nSlice.reducer;

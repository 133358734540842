import React, { FC, useCallback } from "react";
import { useAppSelector } from "../../App/hooks";
import { selectProtocolSummary } from "../../features/summary/protocolSummarySlice";
import { getNumberOfBedsArr } from "./getNumberOfBedsArr";

export const SummaryTableContent: FC = () => {
  const {
    summaries,
    numberOfBeds,
  } = useAppSelector(selectProtocolSummary);

  const numberOfBedsArray = getNumberOfBedsArr(numberOfBeds);

  const getBedResult = useCallback(
    (order, bed) => {
      if (summaries[order]?.beds[bed]) {
        return summaries[order]?.beds[bed];
      }

      return '';
    },
    [summaries]
  )

  const getNurses = useCallback(
    (time, reqNurses, total, isDay, showDay): string => {
      if (
        ((showDay && isDay) || (!showDay && !isDay))
        && reqNurses
      ) {
        return `${total} \n = \n ${reqNurses}`
      }

      return ''
    },
    []
  )

  return (
    <>
      {summaries.map(({ dateTime, isDay, reqNurses, total }, idx) => (
        <tr key={`protocol_bed_${idx}`}>
          <td className="bold">{dateTime}</td>
          {numberOfBedsArray.map((id) => (
            <td
              id={`protocol_bed_${id}`}
              key={`protocol_bed_${id}`}
              className="bed_weight"
            >
              {getBedResult(idx, id)}
            </td>
          ))}

          <td></td>
          <td></td>

          <td className="result">{getNurses(dateTime, reqNurses, total, isDay, true)}</td>
          <td className="result">{getNurses(dateTime, reqNurses, total, isDay, false)}</td>
        </tr>
      ))}
    </>
  )
}
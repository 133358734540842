import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { getReferenceDataMap, ReferenceData, ReferenceDataMap } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { CodeTableRef, I18nTypes, ScreenRef, StringType } from '../../App/types';
import { selectDisplayRulesMetadata } from '../displayRules/displayRulesSlice';
import { selectRdrMapKey, selectUiState } from '../ui/uiSlice';
import { parseReferenceData } from './parseReferenceData';
import { refDataName } from './refDataName';

export type RefData = {
  [key in CodeTableRef]: ReferenceData[];
}

export type ScreenRefDataMap = {
  [key in string]?: RefData;
}

export type ScreenRefDataMapInitialized = {
  [key in string]?: boolean;
}

export interface RefDataState {
  screenRefDataMap: ScreenRefDataMap;
}

const initialState: RefDataState = {
  screenRefDataMap: {},
};

export const getReferenceDataMapThunk = createAsyncThunk<ReferenceDataMap, { screenRef: ScreenRef, rdrQuery: string }>(
  'refdata/referencedatamap',
  async ({ screenRef, rdrQuery }) => await getReferenceDataMap(screenRef, rdrQuery)
);

const handleReferenceDataMap = (action: PayloadAction<ReferenceDataMap>, state: RefDataState) => {
  const { screen, entries } = action.payload;
  state.screenRefDataMap[screen] = parseReferenceData(entries);
}

export const refdataSlice = createSlice({
  name: 'refdata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReferenceDataMapThunk.fulfilled, (state, action) => {
        handleReferenceDataMap(action, state);
      });
  },
});

export const selectScreenRefDataMap = (state: RootState) => state.refdata.screenRefDataMap;

export const selectRefDataInitialized = createSelector(
  [
    selectScreenRefDataMap,
    selectRdrMapKey,
  ],
  (selectScreenRefDataMap, key) => key ? !!selectScreenRefDataMap[key] : null)
;

export const selectRefDataName = createSelector(
  [
    selectScreenRefDataMap,
    selectUiState,
    selectDisplayRulesMetadata,
    (state: RootState, id: I18nTypes, code: StringType) => ({ id, code }),
  ],
  (screenRefDataMap, ui, meta, { id, code }) => {
    const { screen } = ui;
    if (!screen || !meta || !meta[id]) {
      return null;
    }
    const fieldMeta = meta[id];
    return refDataName(fieldMeta.codeTables, code as StringType, screenRefDataMap[screen]);
  },
);

export default refdataSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { getProtocol, HasFieldViolations, submitProtocol } from '../../App/apiWrapper'
import { RootState } from '../../App/store'
import { I18nTypes, RefIdType, ViolationLevel } from '../../App/types'
import { resetViolations, setViolations } from '../violations/violationsSlice'
import { moveChildren } from './moveChildren'
import { findQuestion } from './questionDeepSearch'

export interface Question {
  answer: RefIdType;
  children?: Omit<Question, 'children'>[];
  id: I18nTypes;
}

export interface ProtocolState {
  bed: RefIdType;
  time: RefIdType;
  date: string | null;
  questions: Question[],
  questionsDisplay: Question[],
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  successModalOpen: boolean;
}

export interface SubmitProtocolState extends Omit<ProtocolState, 'questionsDisplay' | 'successModalOpen'> {
}

let initialState: ProtocolState = {
  bed: null,
  time: null,
  date: null,
  questions: [],
  questionsDisplay: [],
  violationLevel: null,
  violationId: null,
  successModalOpen: false
}

export const submitProtocolThunk = createAsyncThunk<SubmitProtocolState & HasFieldViolations, SubmitProtocolState>(
  'submit/protocol',
  async (input, { dispatch }) => {
    const data = await submitProtocol(input)
    if (data.violations.length) {
      dispatch(setViolations({ violations: data.violations }))
    } else {
      dispatch(resetViolations())
    }
    return data
  }
)

export const getProtocolThunk = createAsyncThunk<{ protocol: ProtocolState } & HasFieldViolations, void, { state: RootState }>(
  'get/protocol',
  async (_, { getState }) => {
    const data = await getProtocol()

    if (!data.violations.length) {
      const currentState = getState()
      const questions = data.protocol.questions

      const adjustedData = moveChildren(questions, currentState)

      return { protocol: { ...data.protocol, questionsDisplay: adjustedData }, violations: [] }
    }

    return data
  }
)

export const protocolSlice = createSlice({
  name: 'protocol',
  initialState,
  reducers: {
    setProtocolDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload
    },
    setProtocolBed: (state, action: PayloadAction<RefIdType>) => {
      state.bed = action.payload
    },
    setProtocolTime: (state, action: PayloadAction<RefIdType>) => {
      state.time = action.payload
    },
    handleModalClose: (state) => {
      state.successModalOpen = false
    },
    updateQuestion: (state, { payload }: PayloadAction<Question>) => {
      // we need to update both, display value & protocol value
      const question = findQuestion(state.questions, payload.id)
      const question2 = findQuestion(state.questionsDisplay, payload.id)
      question && (question.answer = payload.answer)
      question2 && (question2.answer = payload.answer)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProtocolThunk.fulfilled, (state, { payload: { protocol } }) => {
        initialState = protocol
        state.questions = protocol.questions
        state.questionsDisplay = protocol.questionsDisplay
        state.violationLevel = null
        state.violationId = null
      })
      .addCase(getProtocolThunk.rejected, (state) => {
        state.violationLevel = ViolationLevel.ERROR
        state.violationId = 'MDNS.COMMON.ER.001'
      })
      .addCase(submitProtocolThunk.fulfilled, (state, { payload: { violations } }) => {
        if (!violations.length) {
          state.questions = initialState.questions;
          state.questionsDisplay = initialState.questionsDisplay
          state.successModalOpen = true
        }
      })
  }
})

export const selectProtocol = (state: RootState) => state.protocol

export const {
  setProtocolDate,
  setProtocolBed,
  setProtocolTime,
  updateQuestion,
  handleModalClose
} = protocolSlice.actions

export default protocolSlice.reducer

import { FormHelperText, Paper, Typography } from '@mui/material'
import React, { FC, memo, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../App/hooks'
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule'
import { selectI18n } from '../../features/i18n/i18nSlice'
import { withTranslations } from '../../features/i18n/withTranslations'
import { Question as QuestionType, updateQuestion } from '../../features/protocol/protocolSlice'
import { getHelpTextItems } from '../../features/violations/getHelpTextItems'
import { FormRdrToggleButtonGroup } from '../common/FormRdrToggleButtonGroup'

interface QuestionProps extends QuestionType, WithDisplayRuleProps {
  childQuestions?: QuestionType['children']
}

const QuestionComponent: FC<QuestionProps> = (
  {
    id,
    childQuestions = [],
    answer,
    hasViolation,
    violationMessages,
    disabled
  }
) => {
  const dispatch = useAppDispatch()

  const handleChange = useCallback(
    (_, answer) => {
      answer && dispatch(updateQuestion({ answer, id }))
    },
    [id, dispatch]
  )
  const helperTextItems = getHelpTextItems(violationMessages)

  return (
    <Paper
      sx={{ p: 3, my: 2, mx: 'auto', maxWidth: 1200, position: 'relative', color: (hasViolation ? 'red' : ''), backgroundColor: (disabled ? 'lightGray' : '') }}
      elevation={2}
    >
      <FormHelperText sx={{ position: 'absolute', top: 0 }} error>{helperTextItems}</FormHelperText>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography align="left">{useAppSelector((state) => selectI18n(state, id))}</Typography>
        {!childQuestions?.length && (
          <FormRdrToggleButtonGroup
            id={id}
            value={answer}
            onChange={handleChange}
            disabled={disabled}
            sx={{maxHeight: 36}}
          />
        )}
      </div>
      {childQuestions.map(({ id: childrenId, answer, ...rest }) => (
        <Question id={childrenId} key={childrenId} answer={answer} {...rest} />
      ))}
    </Paper>
  )
}

export const Question =
  withDisplayRule<QuestionProps>(
    withTranslations<QuestionProps>(
      memo<QuestionProps>(
        QuestionComponent
      )
    )
  )

import React, { FC } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useAppSelector } from "../../App/hooks";
import { selectUiState } from "../../features/ui/uiSlice";

export const AppSpinner: FC = () => {
  const { showLoader } = useAppSelector(selectUiState)

  if (!showLoader) {
    return null
  }

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
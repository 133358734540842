import { selectI18n } from '../i18n/i18nSlice'
import { Question } from './protocolSlice'

export const moveChildren = (arrayOfObjects: Question[], state) => {
  let newObjects: Question[] = []

  arrayOfObjects.forEach((obj) => {
    const { id, children } = obj
    const translation = selectI18n(state, id);
    if (translation === id && children) {
      newObjects.push(...children)
    } else {
      newObjects.push(obj)
    }
  })

  return newObjects
}